// **Welcome!**
//This file is your location to update brand specific data used throughout the application. Please also see the theme file for colors, typography, and additional MUI component customization.

//If you would like to rename any pages, simply change the name of the page folder and component, but don't forget to update the routes.js file with the new path, name and icon you wish to use (these are utilized in the nav menus). To add pages, create a new folder and again, be sure to add it to the routes.js file.

//For wallet page coins displayed, scroll down to pages/dashboard and updated the walletCoins array. Set useBrandCoin to false if you do not yet have your private blockchain coin set up. This will remove it from the wallet dashboard, as well as remove user references to bridging for that coin (page and nav bars)

//logos
import brandIcon from "../assets/icons/spfIcons/logos/green-icon.png";
import brandLogo from "../assets/icons/spfIcons/logos/spf-leaf-logo.svg";
import brandLogoWithText from "../assets/icons/spfIcons/logos/spf-text-logo.svg";
import brandTokenIcon from "../assets/icons/spfIcons/logos/green-logo-round.svg";
import brandPrivateTokenIcon from "../assets/icons/spfIcons/logos/green-native-token.svg";

//images
import pageNotFoundImage from "@/images/page-not-found.png";
import serverErrorImage from "@/images/server-error.png";
import twitterLogo from "@/icons/logos/twitter.svg";
import facebookLogo from "@/icons/logos/facebook.svg";
import linkedInLogo from "@/icons/logos/linkedin.svg";
import discordLogo from "@/icons/logos/discord.svg";
import youtubeLogo from "@/icons/logos/youtube.svg";
import instagramLogo from "@/icons/logos/instagram.svg";

// Fallback Images
import shareHero from "@/images/StoreHero.svg";
import setupWallet from "@/images/Set-Up-Wallet.svg";
import shareSignup from "@/images/SmartWallet.png";
import noReferrals from "@/images/refer-a-friend_no-referrals.svg";
import blockFabric from "@/icons/logos/block-fabric.svg";
import blockFabricHash from "@/icons/logos/block-fabric-hash.svg";
import nodeBanner from "@/images/nodeBanner.svg";
import votingBanner from "@/images/voting.svg";

import { config } from "config";

const brandName = "Set Power Free";
const brandChainName = "Green";
const brandCoin = "GREEN";
const staticSite = "https://setpowerfree.com/";
const rpcUrl = "https://rpc.mainnet.green.blockfabric.net";
const domain = "setpowerfree.com";
const version = "2.6.1-b";
const binaryBrand = "green";
const contractAddress = "0xb2089a7069861c8d90c8da3aacab8e9188c0c531";
const chainID = 6941;
const appUrl = "https://setpowerfree.com/";
const bridgeFee = 1000;
const useV2Endpoints = false;

// Node Governance URL (for prod, use https://element.node-governance.io)
const nodeGovUrl = `https://green.node-governance.io`;

export const brand = {
  // Brand Info
  brandName,
  brandChainName,
  staticSite,
  appUrl,
  nodeGovUrl,
  rpcUrl,
  contractAddress,
  chainID,
  bridgeFee,
  walletMigrationDate: "Dec 25, 2025",
  coin: brandCoin,
  useV2Endpoints,
  // Logos
  brandLogoHorizontalLight: brandLogoWithText.src,
  brandLogoCompactLight: brandLogo.src,
  brandLogoSymbolLight: brandLogo.src,
  brandLogoHorizontalDark: brandLogoWithText.src,
  brandLogoCompactDark: brandLogo.src,
  brandLogoSymbolDark: brandLogo.src,
  authenticationLogo: brandLogo.src,
  logoWithText: brandLogoWithText.src,
  logo: brandLogo.src,
  icon: brandIcon.src,
  tokenIconL2: brandPrivateTokenIcon.src,
  tokenIcon: brandTokenIcon.src,
  // Fallback Images
  fallbackImages: {
    shareHero: shareHero.src,
    shareSignup: shareSignup.src,
    setupWallet: setupWallet.src,
    noReferrals: noReferrals.src,
    blockFabric: blockFabric.src,
    blockFabricHash: blockFabricHash.src,
    nodeBanner: nodeBanner.src,
    votingBanner: votingBanner.src,
  },
  loggedOutHomeRoute: "/", //clicking 'home' like the brand logo - where do you want to take the user, you can also put your static site url here
  loggedInHomeRoute: "/home",
  blockFabricUrl: "https://blockfabric.host/?referralCode=Green-NE",
  blockFabricTxnUrl:
    "https://explore.mainnet.green.blockfabric.net/transactions/", //used to display block transactions on L2->L2 transaction success
  externalWalletEthAddress: "https://etherscan.io/address/",
  support: {
    url: "https://support.setpowerfree.com",
    target: "_blank",
  },
  storeFilterCategories: {
    nodes: "category-2",
    other: "category-1",
  },
  //this key must match what your static marketing site sets in the cookies preferences center
  cookiesPreferencesKey: "NerdCookiesPreferences",
  cookiesDomain:
    config.environment === "local" ? "localhost" : ".setpowerfree.com",
  cookiesExpiration: new Date(Date.now() + 300000), // 5 minutes
  nodeSetupBashCommands: {
    vps: `bash <(curl -s https://download.${domain}/node-binaries/v${version}/linux.sh)`,
    mac: `bash <(curl -s https://download.${domain}/node-binaries/v${version}/mac.sh)`,
    linux: {
      sudo1: 'sudo chown -R "{your_username}:" /usr/local/bin',
      sudo2: 'sudo chown -R "{your_username}:" /etc/systemd/system',
      bash: `bash <(curl -s https://download.${domain}/node-binaries/v${version}/linux.sh)`,
      sudo3: `sudo systemctl start ${binaryBrand}`,
      sudo4: `systemctl status ${binaryBrand}`,
    },
  },
  helpDoc:
    "https://download.nerdunited.net/node-binaries/instructions/green/Node+VPS+Setup+Vultr+Green+Prod.pdf",
  socialMediaSites: {
    twitter: {
      url: "https://twitter.com",
      icon: twitterLogo.src,
      isActive: true,
      buttonBackgroundColor: "#00acee",
    },
    discord: {
      url: "https://discord.com",
      icon: discordLogo.src,
      isActive: true,
      buttonBackgroundColor: "#7289D9",
    },
    linkedIn: {
      url: "https://linkedin.com",
      icon: linkedInLogo.src,
      isActive: true,
      buttonBackgroundColor: "#0a66c2",
    },
    facebook: {
      url: "https://facebook.com",
      icon: facebookLogo.src,
      isActive: true,
      buttonBackgroundColor: "#1877f2",
    },
    instagram: {
      url: "https://instagram.com",
      icon: instagramLogo.src,
      isActive: true,
      buttonBackgroundColor: "transparent",
    },
    youtube: {
      url: "https://youtube.com",
      icon: youtubeLogo.src,
      isActive: true,
      buttonBackgroundColor: "#C4302B",
    },
  },
  errorMessages: {
    genericError: "Something has gone wrong, please try again later.",
    inputValidationFields: {
      name: "Improperly formatted name",
      email: "Enter a valid email (john@example.com)",
      password: "Password does not meet the character requirement",
      confirmPassword: "Passwords do not match",
      checkbox: "You must be over 18 to sign up.",
      phone: "Improperly formatted phone number",
    },
    //ManageAccount/Profile
    birthDateError: "Invalid Date Format, please use MM/DD/YYYY",
    birthDateAge: "Please check the year",
  },
  pages: {
    dashboard: {
      walletCoins: ["ETH", "BTC"],
      useBrandCoin: true, // if your private blockchain coin is not yet set up - change to false to hide it from the wallet dashboard coin list
    },
    referFriend: {
      howPointsAreAwarded: `Explanation about how many points are awarded by each node and signup (decided by ${brandName}).`,
    },
    voting: {
      charterKey: "CharterVoteOfficial.pdf",
      charterFile:
        "https://stage-green-charter-documents.s3.us-west-2.amazonaws.com/CharterVoteOfficial.pdf",
      charterPageQuantity: 22,
    },
    errorPages: {
      serverFailed: {
        title: "500 Internal server error.",
        subText:
          "We are unable to complete this request. Please try again later.",
        notRecordedImage: serverErrorImage.src,
      },
      infoPageOffline: {
        title: "You are currently offline.",
        subText: "Please check your internet connection and try again.",
        image: pageNotFoundImage.src,
      },
    },
  },
};
